<template>
  <div class="outer">
    <div class="title__wrap">
      <h1>Придумайте пароль</h1>
    </div>
    <form @submit.prevent="validatePass">
      <div class="inputs">
        <v-text-field
          :append-icon="showIconHiddenPass ? 'mdi-eye' : 'mdi-eye-off'"
          v-model="valuePass"
          label="Пароль"
          dense
          :rules="password_rules"
          outlined
          class="password-field"
          :class="{ 'error-input': this.password_rules.length > 0 }"
          :type="showIconHiddenPass ? 'text' : 'password'"
          required
          autofocus
          @input="updateInput"
          @click:append="showIconHiddenPass = !showIconHiddenPass"
        ></v-text-field>
      </div>
      <v-btn depressed :color="valuePass ? 'primary' : ''" class="next-btn" block @click="validatePass">
        <div class="btn-content-container">
          <img src="@/assets/loader.svg" alt="loader" class="loader-icon" :class="{ hidden: !isLoading }" />

          Далее
        </div>
      </v-btn>
    </form>
    <p class="politic">Пароль должен быть не менее четырех символов длиной.</p>
  </div>
</template>

<script>
export default {
  props: {
    value: { type: String, required: true },
    // repeat: {type: String, required: true},
    password_rules: { type: Array },
    isLoading: { type: Boolean, required: true },
    // repeat_rules: {type: Array},
    register: { type: Function, required: true },
  },
  data() {
    return {
      valuePass: this.value,
      // valueRepeat: this.repeat,
      showIconHiddenPass: false,
      // showIconHiddenRepeat: false,
    };
  },
  methods: {
    validatePass() {
      this.register();
    },
    updateInput(event) {
      this.valuePass = event;
      this.$emit("input", event);
    },
    // repeatChange(event){
    //     this.valueRepeat = event
    //     this.$emit('repeatpass', event)
    // }
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  margin: 88px 0 50px 0 !important;
  padding: 0 16px !important;
}

.v-form h1 {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 100%;
  text-align: center;
  letter-spacing: -0.005em;

  color: #1a1a1a;
}
.title__wrap {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 32px;
}
.btn-content-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader-icon {
  margin-right: 10px;
  visibility: visible;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-icon.hidden {
  visibility: hidden;
}

.v-form,
.form {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.v-form {
  background: var(--block-color);
  width: 100%;

  border-radius: 10px;
}
.inputs {
  display: flex;
  flex-direction: column;
  // margin-bottom: 28px;
  // gap: 0px;
}
.next-btn {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-top: 2px;
  margin-bottom: 12px;
}
.forgotPassword {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  text-align: center;
  letter-spacing: -0.015em;

  color: #4f70e4;
}
.politic {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  text-align: center;
  letter-spacing: -0.015em;

  color: #868686;
}
.v-input__append-inner {
  div {
    button {
      &::before {
        display: none !important;
      }
    }
  }
}

@media screen and (max-width: 4000px) and (min-width: 769px) {
  .outer {
    max-width: 380px;
    width: 100%;
    align-self: center;
  }
  .title__wrap {
    margin-bottom: 30px;
  }

  .title__wrap h1 {
    font: 700 40px "PT Sans";
    text-align: center;
    line-height: 110%; /* 44px */
    letter-spacing: -0.2px;
  }

  .politic-checkbox {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .politic {
    text-align: left;
  }

  .politic a {
    font: 400 14px "Inter";
    line-height: 130%; /* 18.2px */
    letter-spacing: -0.21px;
  }
}
</style>
