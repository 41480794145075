<template>
  <div>
    <HeaderNav v-if="!$store.state.isNavBarOff" :auth="$store.state.isAuth" />
    <SideBarDesktop v-if="$store.state.isNavBarOff" />

    <div class="wrapper-lc">
      <div class="container"></div>
    </div>
  </div>
</template>

<script>
import HeaderNav from "@/components/HeaderNav.vue";
import SideBarDesktop from "@/components/SideBarDesktop.vue";

export default {
  components: { HeaderNav, SideBarDesktop },
  data() {
    return {
      notification_item: {},
    };
  },
  methods: {
    getNotificationItem() {
      this.notification_item = this.$store.state.notificationList.filter((item) => item?.id == this.$route.params.id)[0];
    },
  },
  watch: {
    "$store.state.notificationList": function () {
      this.notification_item = this.$store.state.notificationList.filter((item) => item?.id == this.$route.params.id)[0];
    },
  },
  mounted() {
    this.getNotificationItem();
  },
};
</script>

<style lang="scss" scoped></style>
