<template>
  <div class="wrapper-profile">
    <div class="v-form">
      <TitleProfile />

      <BodyProfile />

      <v-btn depressed color="primary" class="next-btn" block @click="$router.push({ name: 'LcUpload' }).catch(() => {})">
        <div class="btn-content-container">
          <img src="@/assets/loader.svg" alt="loader" class="loader-icon" :class="{ hidden: !isLoading }" />
          Загрузить новый чек
        </div>
      </v-btn>
    </div>
  </div>
</template>

<script>
import TitleProfile from "@/components/TitleProfile.vue";
import BodyProfile from "@/components/BodyProfile.vue";

export default {
  components: {
    BodyProfile,
    TitleProfile,
  },
  data() {
    return {
      isLoading: false,
    };
  },

  mounted() {
    if (!this.$store.state.isActivetedUser) this.$router.push({ name: "approve-email" }).catch(() => {});
  },
};
</script>

<style scoped>
.v-form__margin {
  margin-top: 241px;
}

.wrapper-profile {
  width: 100%;
  margin-top: -5px !important;
}

.wrapper__lc {
  position: relative;
  z-index: 0;
}

.v-form,
.form {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.v-form {
  background: var(--block-color);
  width: 100%;
  border-radius: 10px;
  min-height: 200px;
}

.alertActiveAccount {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 8px;

  width: 100%;
  min-height: 60px;
  background: #fde4e7;
  border-radius: 6px;
  flex: none;
  order: 0;
  flex-grow: 0;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 110%;
  text-align: center;
  letter-spacing: -0.015em;
  color: #d3626f;
}

.recovery {
  margin-top: -16px !important;
  font-size: 12px;
  color: #0072bc;
  font-weight: 700;
  cursor: pointer;
}

.recovery-scan {
  font-size: 14px !important;
  margin-top: -38px !important;
}

.scanner {
  width: 253px !important;
  height: 260px !important;
  background: #dadada;
  border: 1px solid #db4100;
  border-radius: 8px;
}

.scan__elements {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 54px;
  align-items: center;
}

.scan.active::before,
.scan.active::after,
.scan__elements::before,
.scan__elements::after {
  content: "";
  position: absolute;
  background-image: url("../../assets/angle.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 29px;
  height: 29px;
  z-index: 1;
}

.btn-content-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader-icon {
  margin-right: 10px;
  visibility: visible;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-icon.hidden {
  visibility: hidden;
}

button.next-btn.btn__scan.v-btn.v-btn--block.v-btn--has-bg.theme--light.v-size--default.primary {
  width: 100% !important;
  background-color: #f77988 !important;
  height: 44px;
  margin-top: -14px;
}

.scan__elements::before {
  top: -15px;
  left: -16px;
  transform: rotate(90deg);
}

.scan__elements::after {
  top: 244px;
  left: -16px;
}

.scan.active::before {
  top: 32px;
  right: 21px;
  transform: rotate(180deg);
}

.scan.active::after {
  top: 293px;
  right: 21px;
  transform: rotate(270deg);
}

input[type="file"] {
  display: none;
}
canvas {
  display: none;
}
.scan {
  display: none;
}
.scan.active {
  display: block;
  position: relative;
}

.v-form__scan {
  padding: 47px 40px 32px 40px;
}

.download {
  width: 115% !important;
}

button.next-btn.btn__download.v-btn.v-btn--block.v-btn--has-bg.theme--light.v-size--default.primary {
  width: 100% !important;
  background-color: #0072bc !important;
  height: 44px;
  margin-top: -14px;
}

.hint {
  font-size: 12px;
  color: #666;
  margin-top: -28px;
}

.v-form__accept {
  padding: 32px 22px;
  gap: 54px;
}

.accept__sub,
.accept__btns {
  display: flex;
  flex-direction: column;
}

.accept__sub {
  gap: 20px;
}

.accept__btns {
  gap: 8px;
}

.v-form__downl {
  gap: 40px;
}

.accept__btn {
  margin-top: 0 !important;
}

.check {
  min-height: 420px;
  gap: 32px;
}

input {
  height: 43px !important;
  border: none;
}

.check__subtitle {
  color: #1a1a1a;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: left;
  margin-bottom: 4px !important;
}

.check__wrap {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* label {
    display: none;
} */

.check-btn {
  margin-top: 40px;
}

/* статусы */

.status-accept {
  background-color: #cef7ba !important;
}

button.next-btn.btn-list.v-btn.v-btn--block.v-btn--has-bg.theme--light.v-size--default {
  width: 100%;
  text-align: left;
  position: relative;
  margin-top: 12px !important;
  background: #f1f1f1 !important;
  border-radius: 4px;
  color: #1a1a1a !important;
  font-size: 14px;
}

.btn-list::before {
  content: "";
  background-image: url("../../assets/arrow.svg");
  background-size: contain;
  position: absolute;
  right: 17px;
  top: 17px;
  width: 5px;
  height: 9px;
  z-index: 99;
}

.check__title {
  text-align: left;
  margin-bottom: 20px;
}

.v-btn__content p {
  color: #1a1a1a !important;
  font-size: 14px;
  overflow: hidden;
}

.check-input__disabled {
  background-color: #f1f1f1 !important;
  color: #1a1a1a;
  font-size: 14px;
  border: none;
}

.check-input__disabled div div fieldset {
  border: none !important;
}

span.v-btn__content {
  display: block;
  width: inherit;
}
.container-btnLink {
  display: flex;
  flex-direction: column;
}
.forgotPassword {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  text-align: center;
  letter-spacing: -0.015em;

  color: #4f70e4;
  margin-bottom: 5px;
  cursor: pointer;
}

@media screen and (max-width: 4000px) and (min-width: 769px) {
  .wrapper-profile {
    max-width: 800px;
    width: 100%;
  }

  .v-form {
    border-radius: 16px;
    padding: 60px;
  }
}
</style>
