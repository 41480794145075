<template>
  <div class="wrapper-menu">
    <div class="check-titleBlock">
      <h1 class="check-titleBlock__title">Мои чеки</h1>
      <div class="check-title__counter">{{ this.$store.state.qrcodes.length }}: чека(ов)</div>
    </div>
    <div class="menu-container" v-for="item in menus" :key="item.id" @click="pushInPromo(item.link)">
      <div class="profile-menu__text">
        {{ item.text }}
      </div>
      <div class="profile-menu__arrow"></div>
    </div>
    <div class="menu-container" v-for="item in menuSs" :key="item.id" @click="openLink(item.link)">
      <div class="profile-menu__text">
        <a style="text-decoration: none; color: #1a1a1a">{{ item.text }}</a>
      </div>
      <div class="profile-menu__arrow"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menus: [
        { text: "Загруженные чеки", link: "LcMain" },
        { text: "Сменить личные данные", link: "approveaccount" },
      ],
      menuSs: [
        { text: "Подробнее об акции", link: `${process.env.VUE_APP_PROMO_LINK}` },
        { text: "Правила розыгрыша", link: `${process.env.VUE_APP_PROMO_CDN}/pdf/rules.pdf` },
      ],
    };
  },
  methods: {
    pushInPromo(link) {
      if (link === "TermsPromo") sessionStorage.setItem("lastPath", "profile");

      this.$router.push({ name: link }).catch(() => {});
    },
    openLink(link) {
      window.open(link);
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper-menu {
  margin-top: 0 !important;
  gap: 12px;
  margin-bottom: 8px;
}
.check-titleBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 28px;
}
.check-titleBlock__title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.005em;

  color: #1a1a1a;
}
.check-title__counter {
  min-width: 92px;
  height: 26px;
  text-align: center;
  padding: 10px;
  background: var(--main-color) !important;
  border-radius: 8px;
}
.check-title__counter {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 130%;
  letter-spacing: -0.005em;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.menu-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 44px;
  background: #f6f6f6;
  border-radius: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: -0.015em;
  color: #1a1a1a;
  margin-bottom: 12px;
  padding: 15px 8px;
  cursor: pointer;
}
.check__date {
  width: 50%;
  min-width: 121px;
  padding-right: 12px;
}
.check__status {
  font-size: 12px;
  background: #f5dfcc;
  border-radius: 8px;
  width: 40%;
  padding: 5px 17px;
  min-width: 75px;
}
.check__status.verified {
  background: #deecdc;
}
.check__status.rejected {
  background: #fae3de;
}
.profile-menu__arrow {
  // width: 10%;
  position: absolute;
  right: 14px;
  min-width: 10px;
  height: 10px;
  background-image: url("../assets/arrow.svg");
}
</style>
