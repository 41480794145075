<template>
  <div>
    <HeaderNav :auth="$store.state.isAuth"></HeaderNav>
    <div class="wrapper" v-if="!isRecoveryPass">
      <v-form ref="form" lazy-validation>
        <div class="recovery-title">
          <h1>Введите новый пароль</h1>
        </div>
        <v-text-field
          label="Пароль"
          dense
          clearable
          outlined
          autofocus
          required
          :type="showIconHiddenPass ? 'text' : 'password'"
          :append-icon="showIconHiddenPass ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showIconHiddenPass = !showIconHiddenPass"
          v-model="password"
          :rules="password_rules"
          class="password-field"
          :class="{
            'error-input': this.password_rules.length,
          }"
        ></v-text-field>

        <v-text-field
          dense
          outlined
          required
          clearable
          :type="showIconHiddenRepeat ? 'text' : 'password'"
          :append-icon="showIconHiddenRepeat ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showIconHiddenRepeat = !showIconHiddenRepeat"
          v-model="repeatPassword"
          :rules="password_rules"
          class="password-field"
          :class="{
            'error-input': this.password_rules.length,
          }"
          label="Повторите пароль"
        >
        </v-text-field>

        <v-btn
          depressed
          :disabled="!password.length || !repeatPassword.length"
          :color="password && repeatPassword ? 'primary' : ''"
          class="next-btn"
          block
          @click="doRecovery"
          >Далее</v-btn
        >
        <p class="politic">Пароль должен быть не менее четырех символов длинной</p>
      </v-form>
    </div>
    <div class="wrapper" v-if="isRecoveryPass">
      <v-form ref="form" lazy-validation>
        <div>
          <h1 class="recovery__title">Пароль успешно сохранён</h1>
        </div>
        <v-btn depressed :color="'primary'" class="next-btn" block @click="$router.push({ name: 'login' }).catch(() => {})"
          ><div class="btn-content-container">Войти</div></v-btn
        >
      </v-form>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import HeaderNav from "@/components/HeaderNav.vue";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      password: "",
      repeatPassword: "",
      password_rules: [],
      user_id: "",
      isRecoveryPass: false,
      showIconHiddenPass: false,
      showIconHiddenRepeat: false,
    };
  },
  methods: {
    ...mapActions({ getUserToken: "getUserToken" }),
    doRecovery() {
      if (this.password != this.repeatPassword) {
        this.password_rules = ["Пароль не соответствует требованиям"];
        return true;
      }
      // const pattern = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
      // const pattern1 = /^[?!,.a-zA-Z0-9\s]+$/;

      if (this.password.length < 4) {
        this.password_rules = ["Пароль не соответствует требованиям"];
        return true;
      }
      // if (!pattern1.test(this.password) || !pattern.test(this.password)) {
      //     this.password_rules = ["Пароль не соответствует требованиям"];
      //     return true;
      // }

      this.$nextTick(async () => {
        if (this.$refs.form.validate()) {
          try {
            await axios.patch(`${process.env.VUE_APP_ACCOUNTS}/${this.$store.state.userId}`, {
              password: this.password,
            });

            this.isRecoveryPass = true;
          } catch (error) {
            this.password_rules = ["Произошла ошибка. Попробуйте позже"];
          }
        }
      });
    },
  },

  watch: {
    password() {
      this.password_rules = [];
    },
    repeatPassword() {
      this.password_rules = [];
    },
  },
  async mounted() {
    await this.getUserToken(this.$route.query.token);
  },
  components: { HeaderNav },
};
</script>

<style scoped>
.v-form {
  gap: 15px;
}

p.note {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 130% !important;
}
.recovery {
  margin-top: 12px;
  color: #044ef3;
  font-size: 12px !important;
  cursor: pointer;
}
.wrapper {
  width: 100%;
  border-radius: 10px;
  /* padding: 61.5px 22.5px; */
  /* margin: 128px auto; */
}
.wrapper h1 {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 100%;
  /* identical to box height, or 20px */

  text-align: center;
  letter-spacing: -0.005em;

  color: #1a1a1a;
}
.recovery__title {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 100%;
  /* identical to box height, or 20px */

  text-align: center;
  letter-spacing: -0.005em;

  color: #1a1a1a;
  margin-bottom: 24px;
}
.recovery-title {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 100%;
  text-align: center;
  letter-spacing: -0.005em;

  color: #1a1a1a;
  margin-bottom: 50px;
}

.next-btn {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}
.loader-icon {
  margin-right: 10px;
  visibility: visible;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-icon.hidden {
  visibility: hidden;
}
.politic {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  text-align: center;
  letter-spacing: -0.015em;

  color: #868686;
  margin-top: 12px;
}

@media screen and (max-width: 4000px) and (min-width: 769px) {
  .v-form {
    max-width: 800px;
    gap: 15px;
  }

  .recovery-title h1 {
    font: 700 40px "PT Sans";
  }
}
</style>
