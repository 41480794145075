<template>
  <div class="header__wrap">
    <v-toolbar dense class="header">
      <img src="../assets/logo.svg" alt="логотип" class="header__img" @click="checkRouterScreen" />
      <div class="header__seting">
        <div v-if="auth" class="avatar-container">
          <img
            :src="avatar"
            v-if="$store.state.avatar !== '' && 
                  $store.state.avatar !== undefined && 
                  $store.state.avatar !== null"
            alt="фото"
            class="header__avatar"
            @click="clickAvatar"
          />
          <img src="../assets/avatar.svg" v-else alt="фото" class="header__avatar" @click="clickAvatar" />
        </div>
        <div v-if="!auth" class="link-container">
          <a :href="promoEmail" target="_blank">поддержка</a>
        </div>

        <v-app-bar-nav-icon
          v-if="auth"
          class="header-menuBtn"
          @click.stop="$store.commit('changeVisibleSidebar', true)"
        ></v-app-bar-nav-icon>
      </div>
    </v-toolbar>
  </div>
</template>

<script>
export default {
  props: {
    auth: { type: Boolean, required: true },
  },
  data() {
    return {
      avatar: this.baseUrl(),
      promoEmail: process.env.VUE_APP_PROMO_EMAIL,
    };
  },
  methods: {
    validatePass() {
      this.$cookies.remove("cng_token");
      this.$store.commit("changeQrcodes", []);
      this.$router.push({ name: "login" }).catch(() => {});
    },
    clickAvatar() {
      if (this.$store.state.isActivetedUser) {
        this.$router.push({ name: "profile" }).catch(() => {});
      } else {
        this.$router.push({ name: "approve-email" }).catch(() => {});
      }
    },
    baseUrl() {
      return `${process.env.VUE_APP_AVATARS}/${this.$store.state.avatar}`;
    },
    checkRouterScreen() {
      this.$store.state.isAuth
        ? this.$router.push({ name: "login" }).catch(() => {})
        : this.$router.push({ name: "Start" }).catch(() => {});
    },
  },
  computed: {},
  watch: {
    "$store.state.avatar": function () {
      this.avatar = this.baseUrl();
    },
    avatar() {
      this.avatar = this.baseUrl();
    },
  },
  mounted() {},
};
</script>

<style scoped>
.header {
  width: 100%;
  background: var(--block-color) !important;
  height: 48px;
}

.header__wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
}

.v-toolbar__content {
  padding: 0 5px 0 16px !important;
  justify-content: space-between;
}

.header-menuBtn {
  color: var(--main-color) !important;
}
.v-icon.notranslate.mdi.mdi-menu.theme--light {
  color: var(--main-color) !important;
}
.header__img {
  height: 35px;
  cursor: pointer;
}

.header__seting {
  display: flex;
  align-items: center;
}
.avatar-container {
  display: flex;
  align-items: center;
}
.header__avatar {
  cursor: pointer;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  object-fit: cover;
}
.link-container {
  display: flex;
  align-items: center;
  margin-right: 11px;
}
.link-container a {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  text-align: center;
  letter-spacing: -0.015em;
  text-decoration-line: none;
  cursor: pointer;
  color: var(--second-color) !important;
  padding-bottom: 1px;
  border-bottom: 1px solid var(--second-color);
}
.v-app-bar__nav-icon.v-btn {
  margin-top: 0;
}

.header__seting p {
  font-size: 12px;
  cursor: pointer;
}

.v-sheet.v-toolbar:not(.v-sheet--outlined) {
  box-shadow: none;
}
</style>
