<template>
  <div>
    <HeaderNav v-if="!$store.state.isNavBarOff" :auth="true"></HeaderNav>
    <div class="test">
      <SideBarDesktop v-if="$store.state.isNavBarOff" />
      <div class="wrapper-lc">
        <router-view name="LcMenu"></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderNav from "@/components/HeaderNav.vue";
import SideBarDesktop from "@/components/SideBarDesktop.vue";
import { mapActions } from "vuex";

export default {
  components: { HeaderNav, SideBarDesktop },
  data() {
    return {
      token: undefined,
    };
  },
  methods: {
    ...mapActions({
      getCurrentUserIdFromToken: "getCurrentUserIdFromToken",
    }),
  },

  mounted() {
    this.token = this.$cookies.get("cng_token");
    if (!this.token) this.$router.push({ name: "login" }).catch(() => {});
    this.getCurrentUserIdFromToken();
  },
};
</script>

<style>
.wrapper-lc {
  width: 100%;
  margin: 88px 0 40px 0;
  padding: 0 16px;
}

.v-form,
.form {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.v-form {
  background: var(--block-color);
  width: 100%;
  padding: 32px 20px;
  border-radius: 10px;
}

.recovery {
  font-family: "Inter";
  margin-top: -12px !important;
  font-size: 12px;
  color: #4f70e4;
  font-weight: 500;
  cursor: pointer;
}

input[type="file"] {
  display: none;
}

canvas {
  display: none;
}

.download {
  width: 115% !important;
}

button.next-btn.btn__download.v-btn.v-btn--block.v-btn--has-bg.theme--light.v-size--default.primary {
  width: 100% !important;
  background-color: var(--second-color) !important;
  border-radius: var(--border-radius);
  height: 44px;
  margin-top: -14px;
}

input {
  height: 43px !important;
  border: none;
}

.v-text-field--outlined.v-input--dense .v-label:active {
  transform: translateY(-28px) scale(0.75);
  background: transparent;
  background-color: transparent;
}
.v-text-field--outlined fieldset {
  border-radius: 8px;
  border-color: #cccccc !important;
}

.v-text-field--outlined.v-input--is-focused fieldset {
  border-radius: 8px !important;
  border-color: #808080 !important;
}
.v-input--is-dirty fieldset {
  border-radius: 8px !important;
  border-color: #b3b3b3 !important;
}

.v-icon.v-icon.v-icon--link {
  color: #808080 !important;
}
.v-text-field--outlined.v-input--is-focused .v-icon.v-icon.v-icon--link {
  color: #808080 !important;
}
.v-input--is-dirty .v-icon.v-icon.v-icon--link {
  color: #b3b3b3 !important;
}
.email-field.error-input.v-text-field--outlined fieldset {
  border: 2px solid red !important;
  border-radius: 8px;
}
.email-field.error-input.v-text-field--outlined .v-icon.v-icon.v-icon--link {
  color: red !important;
}
.email-field.true-input.v-text-field--outlined fieldset {
  border: 2px solid #6e941d !important;
  border-radius: 8px;
}
.email-field.true-input.v-text-field--outlined .v-icon.v-icon.v-icon--link {
  color: #6e941d !important;
}

.password-field .v-icon.v-icon.v-icon--link {
  color: #cccccc !important;
}
.password-field.v-input--is-dirty .v-icon.v-icon.v-icon--link {
  color: #b3b3b3 !important;
}
.password-field.v-input--is-focused .v-icon.v-icon.v-icon--link {
  color: #808080 !important;
}
.password-field.error-input.v-text-field--outlined .v-icon.v-icon.v-icon--link {
  color: red !important;
}
.password-field.error-input.v-text-field--outlined fieldset {
  border: 2px solid red !important;
  border-radius: 8px;
}
.password-field.error-input.v-text-field--outlined .v-icon.v-icon.v-icon--link {
  color: red !important;
}
.password-field.true-input.v-text-field--outlined fieldset {
  border: 2px solid #6e941d !important;
  border-radius: 8px;
}

.hint {
  font-size: 12px;
  color: #666;
  margin-top: -28px;
}

.v-form__accept {
  padding: 32px 22px;
  gap: 54px;
}

.accept__sub,
.accept__btns {
  display: flex;
  flex-direction: column;
}

.accept__sub {
  gap: 20px;
}

.accept__btns {
  gap: 8px;
}

.v-form__downl {
  gap: 40px;
}

.accept__btn {
  margin-top: 0 !important;
}

.check {
  min-height: 120px;
  gap: 32px;
}

.check__subtitle {
  color: #1a1a1a;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: left;
  margin-bottom: 4px !important;
}

.check__wrap {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.check-btn {
  margin-top: 40px;
}

.status-accept {
  background-color: #cef7ba !important;
}

button.next-btn.btn-list.v-btn.v-btn--block.v-btn--has-bg.theme--light.v-size--default {
  width: 100%;
  text-align: left;
  position: relative;
  margin-top: 12px !important;
  background: #f1f1f1 !important;
  border-radius: 4px;
  color: #1a1a1a !important;
  font-size: 14px;
}

.btn-list::before {
  content: "";
  background-image: url("../../assets/arrow.svg");
  background-size: contain;
  position: absolute;
  right: 17px;
  top: 17px;
  width: 5px;
  height: 9px;
  z-index: 99;
}

.check__title {
  text-align: left;
  margin-bottom: 20px;
}

.v-btn__content p {
  color: #1a1a1a !important;
  font-size: 14px;
  overflow: hidden;
}

.check-input__disabled {
  background-color: #f1f1f1 !important;
  color: #1a1a1a;
  font-size: 14px;
  border: none;
}

.check-input__disabled div div fieldset {
  border: none !important;
}

.check__content {
  display: flex;
  flex-direction: column;
  gap: 0px;
}

span.v-btn__content {
  display: block;
  width: inherit;
}
.v-text-field .v-input__icon--clear {
  opacity: 1 !important;
}

.test {
  display: flex;
}

@media screen and (max-width: 4000px) and (min-width: 769px) {
  .wrapper-lc {
    padding: 0 80px 0 380px;
    display: flex;
    justify-content: center;
  }
}
</style>
