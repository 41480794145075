<template>
  <div class="wrapper">
    <HeaderNav v-if="!$store.state.isNavBarOff" :auth="$store.state.isAuth" />

    <v-form class="apruve-container" ref="form" lazy-validation>
      <div class="outer">
        <div class="title__wrap">
          <h1>Персональные данные</h1>
          <p>Для участия в акции укажите ФИО и номер телефона для связи</p>
        </div>
        <div class="inputs">
          <v-text-field
            v-model="lastname"
            label="Фамилия"
            autocomplete="name"
            :rules="lastnameRules"
            dense
            outlined
            class="fio-field email-field"
            :class="{ 'error-input': this.lastnameRules.length > 0 }"
            required
            clearable
            autofocus
          >
          </v-text-field>

          <v-text-field
            v-model="firstname"
            label="Имя"
            autocomplete="name"
            :rules="firstnameRules"
            dense
            outlined
            class="fio-field email-field"
            :class="{ 'error-input': this.firstnameRules.length > 0 }"
            required
            clearable
            autofocus
          >
          </v-text-field>

          <v-text-field
            v-model="patronymic"
            label="Отчество"
            autocomplete="name"
            dense
            outlined
            class="fio-field email-field"
            clearable
            autofocus
          >
          </v-text-field>
          <v-text-field
            v-model="phone"
            label="Телефон"
            placeholder="+7"
            :rules="phoneRules"
            dense
            v-mask="'+7 (###) ###-##-##'"
            outlined
            class="email-field"
            :class="{ 'error-input': this.phoneRules.length > 0 }"
            clearable
            v-on:focus="focus"
            v-on:focusout="focusout"
            v-on:keydown.delete="
              () => {
                if (phone?.length <= 2) {
                  $event.preventDefault();
                }
              }
            "
            v-on:keydown.backspace="
              () => {
                if (phone?.length <= 2) {
                  $event.preventDefault();
                }
              }
            "
          >
          </v-text-field>
        </div>
        <v-btn depressed :color="'primary'" class="next-btn" block @click="register">Сохранить</v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import HeaderNav from "@/components/HeaderNav.vue";
import jwt_decode from "jwt-decode";
import axios from "axios";

function containsNumbers(str) {
  return /\d/.test(str);
}

export default {
  props: {},
  data() {
    return {
      user_id: jwt_decode(this.$cookies.get("cng_token"))["id"],
      firstname: this.$store.state.fio.split(" ")[0] || "",
      lastname: this.$store.state.fio.split(" ")[1] || "",
      patronymic: this.$store.state.fio.split(" ")[2] || "",
      phone: this.$store.state.phone || "",
      phoneRules: [],
      fioRules: [],
      firstnameRules: [],
      lastnameRules: [],
    };
  },
  methods: {
    focus() {
      if (this.phone?.length <= 2 || this.phone === null) {
        this.phone = "+7";
      }
    },
    async focusout() {
      if (this.phone?.length <= 2 || this.phone === null) {
        this.phone = "";
        await this.$nextTick();
        this.phone = "";
      }
    },
    register: async function () {
      let hasErrors = false;

      // Validate phone number
      let phone_numeric = this.phone.match(/\d/g);
      if (!phone_numeric || phone_numeric.length !== 11) {
        this.phoneRules = ["Некорректный номер телефона"];
        hasErrors = true;
      } else {
        this.phoneRules = [];
      }

      // Validate firstname and lastname
      if (containsNumbers(this.firstname) || !this.firstname || this.firstname === "") {
        this.firstnameRules = ["Некорректное имя"];
        hasErrors = true;
      } else {
        this.firstnameRules = [];
      }

      if (containsNumbers(this.lastname) || !this.lastname || this.lastname === "") {
        this.lastnameRules = ["Некорректная фамилия"];
        hasErrors = true;
      } else {
        this.lastnameRules = [];
      }

      // If validation failed, stop execution
      if (hasErrors) {
        return;
      }

      try {
        const fioOneString = `${this.firstname} ${this.lastname} ${this.patronymic || ""}`;
        const { data } = await axios.patch(`${process.env.VUE_APP_MYHOST}`, {
          id: this.user_id,
          phone: this.phone,
          fio: fioOneString,
        });

        this.$store.commit("isAcitvetedUser", true);
        this.$store.commit("changeFio", fioOneString);
        this.$cookies.set("cng_token", data.jwt);
        this.$router.push({ name: "LcMain" }).catch(() => {});
      } catch (error) {
        console.error(error.message);
      }
    },
  },
  watch: {
    async phone() {
      if (this.phone === null) {
        await this.$nextTick();
        this.phone = "+7";
      }
      this.phoneRules = [];
    },
    firstname() {
      this.firstnameRules = [];
    },
    lastname() {
      this.lastnameRules = [];
    },
  },
  components: { HeaderNav },
};
</script>

<style lang="scss" scoped>
.apruve-container {
  gap: 0px !important;
}
.wrapper {
  width: 100%;
  margin: 0 !important;
  padding: 0 !important;
}
.title__wrap {
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-bottom: 48px;
}
.title__wrap p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  text-align: center;
  letter-spacing: -0.015em;

  color: #1a1a1a;
}
.inputs .fio-field {
  margin-bottom: 16px;
}
.v-form h1 {
  font-family: "PT Sans";
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  letter-spacing: -0.005em;
  text-align: center;
}

.v-form,
.form {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.v-form {
  background: var(--block-color);
  width: 100%;

  border-radius: 16px;
}
.inputs {
  display: flex;
  flex-direction: column;
  margin-bottom: 28px;
  gap: 0px;
}
.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot {
  margin-bottom: 6px !important;
}

.next-btn {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin: 0px;
}
.politic {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  text-align: center;
  letter-spacing: -0.015em;

  color: #868686;
}
.politic a {
  color: #868686;
}

@media screen and (max-width: 4000px) and (min-width: 769px) {
  .apruve-container {
    max-width: 800px;
  }
  .outer {
    max-width: 380px;
    width: 100%;
    padding: 80px 0;
    align-self: center;
  }
  .title__wrap {
    margin-bottom: 30px;
  }

  .title__wrap h1 {
    font: 700 36px "PT Sans";
    text-align: center;
    line-height: 110%; /* 44px */
    letter-spacing: -0.2px;
  }

  .politic-checkbox {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .politic {
    text-align: left;
  }

  .politic a {
    font: 400 14px "Inter";
    line-height: 130%; /* 18.2px */
    letter-spacing: -0.21px;
  }

  .inputs {
    margin-bottom: 0;
  }
  .inputs .fio-field {
    margin-bottom: 0;
  }
}
</style>
