<template>
  <div class="wrapper-title">
    <div class="user-photo">
      <input type="file" id="avatar" ref="uploader" @change="uploadAvatar" />
      <img
        :src="avatar"
        v-if="$store.state.avatar !== '' && $store.state.avatar !== undefined && $store.state.avatar !== null"
        alt="фото"
        on-beforeinput="handleFileImport"
        @click="handleFileImport"
        class="user-photo__avatar"
      />
      <img src="@/assets/avatar.svg" v-else alt="фото" @click="handleFileImport" class="user-photo__avatar" />
    </div>
    <div class="user-info">
      <div class="user-info__name">
        {{ this.$store.state.fio }}
      </div>
      <div class="user-info__contacts">
        <div class="user-info__contacts__email">
          {{ this.$store.state.email }}
        </div>
        <div class="user-info__contacts__phone">
          {{ this.$store.state.phone }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      avatar: this.baseUrl(),
      selectedFile: null,
      isSelecting: false,
    };
  },
  methods: {
    handleFileImport() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );
      this.$refs.uploader.click();
    },
    async uploadAvatar(e) {
      try {
        const formData = new FormData();
        this.selectedFile = e.target.files[0];

        formData.append("file", this.selectedFile);
        formData.append("id", this.$store.state.userIdFromToken);

        if (this.selectedFile) {
          const { data } = await axios.patch(`${process.env.VUE_APP_NT}/accounts/avatar`, formData);

          this.$store.commit("changeAvatar", data.data.avatar);
        }
      } catch (error) {
        alert("Не удалось сохранить аватар");
      }
    },
    baseUrl() {
      return `${process.env.VUE_APP_AVATARS}/${this.$store.state.avatar}`;
    },
  },

  watch: {
    "$store.state.avatar": function () {
      this.avatar = this.baseUrl();
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper-title {
  display: flex;
  background-color: var(--block-color);
  padding: 32px 20px 0 20px;
}
.user-photo {
  position: relative;
  margin-right: 20px;
}
.user-photo::before {
  content: "";
  position: absolute;
  background-color: #f1f1f1;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  left: 40px;
  top: 40px;
  background-image: url("@/assets/plus.svg");
  background-position: center;
  cursor: pointer;
}
.user-photo__avatar {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}
.user-info {
  display: flex;
  flex-direction: column;
}
.user-info__name {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 130%;
  text-align: start;
  flex-wrap: wrap;

  letter-spacing: -0.005em;
  color: #1f1f30;
}
.user-info__contacts {
  flex-wrap: wrap;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;

  letter-spacing: -0.015em;

  color: #1f1f30;
}
.user-info__contacts__phone {
  position: relative;
  padding-left: 12px;
  margin-left: 10px;
  text-align: start;
}
.user-info__contacts__phone::after {
  content: "";
  position: absolute;
  left: -11px;
  top: 3px;
  width: 15px;
  height: 15px;
  background-image: url("@/assets/phone.svg");
  background-position: center;
}
.user-info__contacts__email {
  position: relative;
  padding-left: 15px;
  margin-left: 9px;
  padding-right: 19px;
  text-align: start;
}
.user-info__contacts__email::after {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  left: -10px;
  top: 3px;
  background-image: url("@/assets/email.svg");
  background-position: center;
}
</style>
