<template>
  <div>
    <v-navigation-drawer
      v-model="$store.state.isVisibleSidebar"
      absolute
      top
      overlay-color="rgb(0, 0, 0)"
      overlay-opacity="0.4"
      class="sidebar"
    >
      <div class="sidebar-logo" @click="$router.push({ name: 'profile' }).catch(() => {})">
        <img src="../assets/logo.svg" alt="логотип" class="header__img" />
      </div>

      <v-list nav dense>
        <v-list-item-group v-model="group" active-class="deep-purple--text text--accent-4">
          <v-list-item
            v-if="!this.$store.state.isActivetedUser"
            @click="$router.push({ name: 'approve-email' }).catch(() => {})"
            :class="{
              activeItem: $route.path === '/approve-email',
              notActiveItem: $route.path !== '/approve-email',
            }"
          >
            <v-list-item-title>Подтвердить email</v-list-item-title>
          </v-list-item>

          <v-list-item
            v-if="this.$store.state.isActivetedUser"
            aria-selected="true"
            @click="$router.push({ name: 'profile' }).catch(() => {})"
            :class="{
              activeItem: $route.path === '/profile',
              notActiveItem: $route.path !== '/profile',
            }"
          >
            <v-list-item-title>Мой профиль</v-list-item-title>
          </v-list-item>

          <v-list-item
            aria-selected="false"
            @click="$router.push({ name: 'LcUpload' }).catch(() => {})"
            :class="{
              activeItem: $route.path === '/upload',
              notActiveItem: $route.path !== '/upload',
            }"
          >
            <v-list-item-title>Загрузить новый чек</v-list-item-title>
          </v-list-item>

          <v-list-item
            aria-selected="false"
            @click="$router.push({ name: 'LcMain' }).catch(() => {})"
            :class="{
              activeItem: $route.path === '/lc/',
              notActiveItem: $route.path !== '/lc/',
            }"
          >
            <v-list-item-title>Загруженные чеки</v-list-item-title>
          </v-list-item>

          <v-list-item
            :class="{
              activeItem: $route.path === '/notification',
              notActiveItem: $route.path !== '/notification',
            }"
          >
            <v-list-item-title @click="$router.push({ name: 'notifications' }).catch(() => {})" class="sidebar-notifications"
              >Уведомления
              <div class="sidebar-notifications__counter">
                {{ this.$store.state.filteredNotification }}
              </div>
            </v-list-item-title>
          </v-list-item>

          <v-list-item
            aria-selected="false"
            :class="{
              activeItem: $route.path === '/prize',
              notActiveItem: $route.path !== '/prize',
            }"
            @click="$router.push({ name: 'prize' }).catch(() => {})"
          >
            <v-list-item-title class="sidebar-notifications"
              >Вручение призов
              <div class="sidebar-notifications__counter">
                {{ this.$store.state.prizeList.length }}
              </div>
            </v-list-item-title>
          </v-list-item>

          <v-list-item :class="{ notActiveItem: $route.path !== '/support' }">
            <a :href="promoEmail" target="_blank">
              <v-list-item-title>Поддержка</v-list-item-title>
            </a>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <div class="sidebar-exitBtn">
        <p @click="exit">Выйти</p>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: false,
      group: null,
      isActiveItem: true,
      promoEmail: process.env.VUE_APP_PROMO_EMAIL,
    };
  },
  methods: {
    exit() {
      this.$cookies.remove("cng_token");
      sessionStorage.clear();
      this.$store.commit("cleaningStore");
      this.$router.push({ name: "login" }).catch(() => {});
    },
    getNewNotification(notifications) {
      const newNotifications = notifications.filter((item) => !item.status);
      return newNotifications.length;
    },
  },

  watch: {
    group() {
      this.$store.commit("changeVisibleSidebar", false);
    },
    "$route.path": function () {},
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.v-navigation-drawer {
  position: fixed;
  justify-content: start;
  text-align: start;
  align-items: start;
  z-index: 100 !important;
  width: 272px !important;
  background-color: var(--block-color);
}
.v-navigation-drawer__content {
  width: 100% !important;
}
.v-list-item {
  height: 48px;
}
.v-list-item a {
  height: 48px;
  display: flex;
  align-items: center;
}
.v-list-item__title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #1a1a1a !important;
}

.activeItem.v-list-item--link:before {
  background-color: #f1f1f1 !important;
}
.notActiveItem.v-list-item--link:before {
  background-color: #f1f1f1 !important;
}
.v-list-item--link:before {
  background-color: var(--block-color) !important;
}

.v-list.v-sheet {
  padding: 0;
  width: 100%;
}
.activeItem {
  background-color: #f1f1f1 !important;
  background: #f1f1f1 !important;
  border-radius: 8px;
}
.notActiveItem {
  background-color: var(--block-color) !important;
  background: var(--block-color) !important;
  border-radius: 8px;
}
.v-list-item {
  padding: 0 16px;
  width: 100%;
}
.v-list-item a {
  text-decoration: none !important;
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #1a1a1a !important;
  outline: none !important;
  width: 100%;
  height: 100%;
}
.sidebar-notifications {
  display: flex;
  align-items: center;
}
.sidebar-notifications__counter {
  width: 24px !important;
  height: 24px !important;
  margin-left: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--main-color);
  border-radius: 50%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;

  letter-spacing: -0.015em;

  color: var(--block-color);
}
.sidebar-logo {
  padding: 24px 16px;
  width: 25%;
  cursor: pointer;
}
.sidebar-exitBtn {
  position: absolute;
  bottom: 0vh;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  width: 100%;

  color: var(--main-color);
}
.sidebar-exitBtn p {
  cursor: pointer;
  padding: 5px 24px 28px 24px;
}

.header__img {
  cursor: pointer;
  height: 35px;
}
</style>
