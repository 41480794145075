<template>
  <div class="v-form v-form__check check" v-if="current_qr">
    <div class="check-title">Чек</div>
    <div class="check__wrap">
      <div class="check__content">
        <p class="check__subtitle">Дата и время загрузки</p>
        <v-text-field outlined class="check-input__disabled" :value="`${getDate(current_qr.create_date)}`" dense disabled></v-text-field>
      </div>
      <div class="check__content">
        <p class="check__subtitle">Дата и время покупки</p>
        <v-text-field outlined class="check-input__disabled" :value="`${getDate(current_qr.time)}`" dense disabled></v-text-field>
      </div>
      <div class="check__content">
        <p class="check__subtitle">Сумма</p>
        <v-text-field
          outlined
          class="check-input__disabled"
          :value="current_qr.summary ? current_qr.summary : ''"
          dense
          disabled
        ></v-text-field>
      </div>
      <div class="check__content">
        <p class="check__subtitle">ФД - номер</p>
        <v-text-field outlined class="check-input__disabled" :value="current_qr.fd ? current_qr.fd : ''" dense disabled></v-text-field>
      </div>
      <div class="check__content">
        <p class="check__subtitle">ФН - номер</p>
        <v-text-field outlined class="check-input__disabled" :value="current_qr.fn ? current_qr.fn : ''" dense disabled></v-text-field>
      </div>
      <div class="check__content">
        <p class="check__subtitle">ФП - номер</p>
        <v-text-field outlined class="check-input__disabled" :value="current_qr.fp ? current_qr.fp : ''" dense disabled></v-text-field>
      </div>
      <div class="check__content">
        <p class="check__subtitle">Статус проверки</p>
        <v-text-field
          outlined
          class="check-input__disabled"
          :class="{
            verified: current_qr.status === 'verified',
            wait: current_qr.status === 'wait',
            rejected: current_qr.status === 'notVerified',
            onHold: current_qr.status === 'onHold',
          }"
          :value="statuses[current_qr.status]"
          dense
          disabled
        ></v-text-field>
      </div>
      <div class="check__content">
        <p class="check__subtitle">Дополнительная информация</p>
        <v-text-field
          outlined
          class="check-input__disabled"
          :value="current_qr.reason ? current_qr.reason : ''"
          dense
          disabled
        ></v-text-field>
      </div>
    </div>
    <v-btn depressed color="primary" class="next-btn check-btn" block @click="$router.push({ name: 'LcUpload' }).catch(() => {})">
      <div class="btn-content-container">
        <img src="@/assets/loader.svg" alt="loader" class="loader-icon" :class="{ hidden: !isLoading }" />
        Загрузить новый чек
      </div></v-btn
    >
    <p class="recovery" @click="$router.push({ name: 'LcMain' }).catch(() => {})">Вернуться к&nbsp;списку чеков</p>
  </div>
</template>
<script>
import moment from "moment";
import axios from "axios";

export default {
  data() {
    return {
      current_qr: undefined,
      isLoading: false,
      statuses: {
        wait: "Проверка",
        verified: "Принято",
        notVerified: "Отклонено",
        onHold: "На ожидании",
      },
    };
  },
  methods: {
    getDate(fullDate) {
      if (fullDate === "none") return "none";

      return moment(fullDate).utc().local().format("DD.MM.YYYY HH:mm");
    },
  },
  async mounted() {
    try {
      const { data } = await axios.get(`${process.env.VUE_APP_QR}/${this.$route.params.id}`);

      this.current_qr = data;
    } catch (error) {
      console.error(error.message);
    }
  },
};
</script>

<style scoped>
.v-form.v-form__check.check {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 100%;
  text-align: center;
  letter-spacing: -0.005em;

  color: #1a1a1a;
}
.check__wrap {
  gap: 18px;
}
.check-title {
  margin-bottom: 32px;
}
.check__subtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: -0.015em;
  color: #1a1a1a;
  margin-bottom: 8px !important;
}

.check-input__disabled {
  background-color: #f1f1f1 !important;
  background: #f6f6f6;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: -0.015em;

  color: #1a1a1a !important;
  border-radius: 10px;
  height: 43px;
}
.check-input__disabled.wait {
  background-color: #b8b8b8 !important;
}
.check-input__disabled.verified {
  background-color: #deecdc !important;
}
.check-input__disabled.rejected {
  background-color: #fae3de !important;
}
.check-input__disabled.onHold {
  background-color: #7a96e3 !important;
}
.btn-content-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  text-align: center;
  letter-spacing: -0.015em;

  color: #ffffff;
}
.check-btn {
  margin-bottom: 12px;
}
.loader-icon {
  margin-right: 10px;
  visibility: visible;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.recovery {
  padding: 0;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  text-align: center;
  letter-spacing: -0.015em;
  color: #4f70e4;
  margin-top: 0 !important;
}
.loader-icon.hidden {
  visibility: hidden;
}

@media screen and (max-width: 4000px) and (min-width: 769px) {
  .v-form {
    max-width: 800px;
    width: 100%;
    padding: 60px;
    border-radius: 16px;
  }

  .check-title {
    margin-bottom: 0;

    font: 700 40px "PT Sans";
    line-height: 110%; /* 44px */
    letter-spacing: -0.2px;
    text-align: left;
  }
  .next-btn {
    margin-top: 0;
  }
}
</style>
