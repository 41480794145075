<template>
  <div class="wrapper">
    <HeaderNav v-if="!$store.state.isNavBarOff" :auth="$store.state.isAuth" />

    <div class="v-form v-form__accept">
      <div>
        <div class="accept__sub">
          <h1>Чек принят на рассмотрение</h1>
          <div class="alertActiveAccount">Подтвердите адрес электронной почты для участия в акции</div>
        </div>
        <div class="accept__btns">
          <v-btn depressed color="primary" class="next-btn btn__download" block @click="$router.push({ name: 'LcMain' }).catch(() => {})">
            <div class="btn-content-container">
              <img src="@/assets/loader.svg" alt="loader" class="loader-icon" :class="{ hidden: !isLoading }" />
              Мои чеки
            </div>
          </v-btn>
          <v-btn depressed color="primary" class="next-btn accept__btn" block @click="$router.push({ name: 'LcUpload' }).catch(() => {})">
            <div class="btn-content-container">
              <img src="@/assets/loader.svg" alt="loader" class="loader-icon" :class="{ hidden: !isLoading }" />
              Загрузить новый чек
            </div>
          </v-btn>
          <p class="politic">Письмо с инструкцией отправлено на {{ this.$store.state.email }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HeaderNav from "@/components/HeaderNav.vue";

export default {
  data() {
    return {
      isLoading: false,
    };
  },
  components: { HeaderNav },
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  margin: 0px 0 0 0 !important;
  padding: 0 !important;
}

.v-form h1 {
  font-family: "PT Sans";
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  letter-spacing: -0.005em;
  text-align: center;
}
.title__wrap {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 32px;
}
.btn-content-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader-icon {
  margin-right: 10px;
  visibility: visible;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-icon.hidden {
  visibility: hidden;
}
.accept__sub {
  margin-bottom: 32px;
}

.v-form,
.form {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.v-form {
  background: var(--block-color);
  width: 100%;
  border-radius: 16px;
}
.next-btn {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 12px;
}
.accept__btns button.next-btn.btn__scan.v-btn.v-btn--block.v-btn--has-bg.theme--light.v-size--default.primary {
  width: 100% !important;
  background-color: #f77988 !important;
  height: 44px;
  margin-top: 0px;
  margin-bottom: 12px !important;
}
.forgotPassword {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  text-align: center;
  letter-spacing: -0.015em;

  color: #4f70e4;
}
.politic {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  text-align: center;
  letter-spacing: -0.015em;

  color: #868686;
}

.alertActiveAccount {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  margin-bottom: 12px;
  width: 100%;
  min-height: 60px;
  background: #fcd3d3;
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 0;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 110%;
  text-align: center;
  letter-spacing: -0.015em;
  color: var(--main-color);
}

@media screen and (max-width: 4000px) and (min-width: 769px) {
  .v-form {
    max-width: 800px;
    width: 100%;
    border-radius: 10px;
    padding: 60px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .accept__sub h1 {
    font: 700 40px "PT Sans";
    line-height: 110%; /* 44px */
    letter-spacing: -0.2px;
  }

  .check__wrap {
    width: 100%;
  }
}
</style>
