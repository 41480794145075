import Vue from "vue";
import Vuex from "vuex";
import jwt_decode from "jwt-decode";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isVisibleSidebar: false,
    isActivetedUser: true,
    isVisiblePopupNotification: false,
    isAuth: false,
    email: "",
    fio: "",
    phone: "",
    avatar: "",
    qrcodes: [],
    notificationList: [],
    isLoadingPrizeList: false,
    prizeList: [],
    filteredNotification: [],
    firstNoReadenNotification: "",
    userId: "",
    userIdFromToken: null,
    isNavBarOff: false,
    registerEmail: "",
    registerPassword: "",
  },

  mutations: {
    changeRegisterEmail(state, email) {
      state.registerEmail = email;
    },
    changeRegisterPassword(state, password) {
      state.registerPassword = password;
    },
    changeVisibilityNavBar(state, bool) {
      state.isNavBarOff = bool;
    },
    changeUserId(state, userId) {
      state.userId = userId;
    },
    changeVisibleSidebar(state, bool) {
      state.isVisibleSidebar = bool;
    },
    changeVisiblePopupNotification(state, bool) {
      state.isVisiblePopupNotification = bool;
    },
    isAcitvetedUser(state, bool) {
      state.isActivetedUser = bool;
    },
    changeFio(state, fio) {
      state.fio = fio;
    },
    changeUserIdFromToken(state, userIdFromToken) {
      state.userIdFromToken = userIdFromToken;
    },
    changePhone(state, phone) {
      state.phone = phone;
    },
    changeEmail(state, email) {
      state.email = email;
    },
    changeAvatar(state, avatar) {
      state.avatar = avatar;
    },
    changeQrcodes(state, qrcodes) {
      state.qrcodes = qrcodes;
    },
    changeIsAuth(state, bool) {
      state.isAuth = bool;
    },
    changeNotifications(state, notifications) {
      state.notificationList = notifications;
    },
    changePrize(state, prize) {
      state.prizeList = prize;
    },
    changeIsLoadingPrizeList(state, isLoading) {
      state.isLoadingPrizeList = isLoading;
    },
    getUnreadNotifications(state, filteredNotification) {
      const count = filteredNotification.filter((el) => el.status === false);
      state.filteredNotification = count.length;
    },
    cleaningStore(state) {
      (state.isVisibleSidebar = false),
        (state.isActivetedUser = true),
        (state.isVisiblePopupNotification = false),
        (state.isAuth = false),
        (state.email = ""),
        (state.fio = ""),
        (state.phone = ""),
        (state.avatar = ""),
        (state.qrcodes = []),
        (state.notificationList = []),
        (state.firstNoReadenNotification = ""),
        (state.userIdFromToken = null);
    },
  },

  actions: {
    getRegistrationData({ commit }, email, password) {
      commit("changeRegisterEmail", email);
      commit("changeRegisterPassword", password);
    },
    async getUserData({ commit }) {
      try {
        const { data } = await axios.post(`${process.env.VUE_APP_MYHOST}/getuserdata`, {
          token: Vue.$cookies.get("cng_token"),
        });

        data.status === "active" ? commit("isAcitvetedUser", true) : commit("isAcitvetedUser", false);

        commit("changeIsAuth", true);
        commit("changeEmail", data.email);
        commit("changePhone", data.phone);
        commit("changeFio", data.fio);
        commit("changeAvatar", data.avatar);
      } catch (error) {
        console.error(error.message);
      }
    },

    async getCurrentUserIdFromToken({ commit }) {
      const token = jwt_decode(Vue.$cookies.get("cng_token"))["id"];
      if (!token) this.$router.push({ name: "login" }).catch(() => {});

      commit("changeUserIdFromToken", token);
    },

    async getUserNotification({ commit }) {
      try {
        const user_id = jwt_decode(Vue.$cookies.get("cng_token"))["id"];
        if (!user_id) this.$router.push({ name: "login" }).catch(() => {});

        const { data } = await axios.get(`${process.env.VUE_APP_NT}/notifications/${user_id}`);

        commit("changeNotifications", data);
        commit("getUnreadNotifications", data);
      } catch (e) {
        commit("changeNotifications", []);
        commit("getUnreadNotifications", []);
        console.error(e.message);
      }
    },

    async getUserPrize({ commit }) {
      try {
        const user_id = jwt_decode(Vue.$cookies.get("cng_token"))["id"];
        if (!user_id) this.$router.push({ name: "login" }).catch(() => {});

        commit("changeIsLoadingPrizeList", true);

        const { data } = await axios.get(`${process.env.VUE_APP_NT}/raffle/get-winner/${user_id}`);

        commit("changePrize", data.data);
      } catch (e) {
        commit("changePrize", []);
        console.error(e.message);
      } finally {
        commit("changeIsLoadingPrizeList", false);
      }
    },

    async changeStatusNotification({ dispatch }, id) {
      try {
        await axios.put(`${process.env.VUE_APP_NT}/notifications`, { id });

        dispatch("getUserNotification");
      } catch (error) {
        console.error(error.message);
      }
    },

    async getQrcodes({ commit }) {
      try {
        const user_id = jwt_decode(Vue.$cookies.get("cng_token"))["id"];
        if (!user_id) this.$router.push({ name: "login" }).catch(() => {});

        const { data } = await axios.get(`${process.env.VUE_APP_QR}/userchecks/${user_id}`);

        commit("changeQrcodes", data.data);
      } catch (e) {
        console.error(e.message);
      }
    },

    async getUserToken({ commit }, token) {
      try {
        const user_id = jwt_decode(token)["id"];
        if (!user_id) this.$router.push({ name: "login" }).catch(() => {});

        const { data } = await axios.get(`${process.env.VUE_APP_ACCOUNTS}/${user_id}`);

        commit("changeUserId", data.data.id);
      } catch (error) {
        console.error(error.message);
      }
    },

    onResize({ commit }) {
      const screenWidth = window.innerWidth;

      screenWidth > 768 ? commit("changeVisibilityNavBar", true) : commit("changeVisibilityNavBar", false);
    },
  },
});
